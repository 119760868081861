<template>
	<div>
		<PageHeader title="Settings"
					:show-settings-button="false"/>
		<div class="settings">
			<div class="container">
				<Tab class="settings-btns" :items="tabItems"></Tab>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
    import PageHeader from "../../../../../shared/components/PageHeader";
    import Tab from "../../../../../shared/components/Tab"
    export default {
        name: "Settings",
        components: {Tab, PageHeader},
        data() {
            return {
                tabItems: [
                    {
                        name: 'account',
                        route: {name: 'dashboard.settings.account'},
                        title: 'Account'
                    },
                    {
                        name: 'billing',
                        route: {name: 'dashboard.settings.billing'},
                        title: 'Billing & My Plan'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/settings";
</style>
